import { useIntl } from 'react-intl';

import { Button, LinearProgress, Stack, Typography } from '@trustyou/ui';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';
import { useFeedbackPaginationStore } from '../../../../store/feedback-pagination-store';

export type SurveyPaginationProps = {
  pageCount: number;
  showProgressBar?: boolean;
  onValid?: () => Promise<boolean>;
};

export function SurveyPagination({ pageCount, showProgressBar, onValid }: SurveyPaginationProps) {
  const intl = useIntl();
  const { currentPage, setCurrentPage } = useFeedbackPaginationStore();
  const progress = (currentPage / pageCount) * 100;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;

  const navigateToNextPage = async () => {
    const isValid = await onValid?.();
    if (!isValid) return;
    setCurrentPage(currentPage + 1);
  };

  const navigateToPreviousPage = async () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          variant={showProgressBar || (!showProgressBar && isLastPage) ? 'text' : 'contained'}
          sx={{
            textTransform: 'uppercase',
            visibility: isFirstPage ? 'hidden' : 'visible',
          }}
          onClick={navigateToPreviousPage}
        >
          {intl.formatMessage(submissionMessages.paginationBack)}
        </Button>
        {showProgressBar && (
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage(submissionMessages.paginationPage, {
              naturalPage: currentPage,
              pageCount,
            })}
          </Typography>
        )}
        <Button
          variant={showProgressBar ? 'text' : 'contained'}
          sx={{
            textTransform: 'uppercase',
            visibility: isLastPage ? 'hidden' : 'visible',
          }}
          onClick={navigateToNextPage}
        >
          {intl.formatMessage(submissionMessages.paginationNext)}
        </Button>
      </Stack>
      {showProgressBar && (
        <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: { md: 1 } }} />
      )}
    </Stack>
  );
}
