import { useIntl } from 'react-intl';

import { SurveyMessageContent, submissionMessages } from '@trustyou/survey-manager';

import type { ThankyouMessage } from './client';
import { PublishOnSourceDialog } from './publish-on-source-dialog';

type ThankYouProps = {
  message?: ThankyouMessage;
  language: string;
};

export function ThankYou({ message, language }: ThankYouProps) {
  const intl = useIntl();

  return (
    <>
      {message ? (
        <SurveyMessageContent
          title={message.title?.[language]}
          description={message.description?.[language]}
        />
      ) : (
        <SurveyMessageContent
          title={intl.formatMessage(submissionMessages.defaultTitle)}
          description={intl.formatMessage(submissionMessages.defaultDescription)}
        />
      )}
      <PublishOnSourceDialog />
    </>
  );
}
