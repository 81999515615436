import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { type TextFieldProps, ThemeProvider } from '@mui/material';
import { openInNewTab } from '@trustyou/shared';
import { submissionMessages } from '@trustyou/survey-manager';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  theme as defaultTheme,
} from '@trustyou/ui';

import { useURLToPublish } from './service/url-to-publish';
import { usePublishToSourceStore } from './store/publish-to-source-store';

export function PublishOnSourceDialog() {
  const intl = useIntl();

  const isDialogOpen = usePublishToSourceStore((state) => state.isDialogOpen);
  const setIsDialogOpen = usePublishToSourceStore((state) => state.setIsDialogOpen);
  const reviewTextQuestionId = usePublishToSourceStore((state) => state.reviewTextQuestionId);
  const entityId = usePublishToSourceStore((state) => state.entityId);

  const initialValue = useWatch({ name: reviewTextQuestionId });
  const [reviewTextToCopy, setReviewTextToCopy] = useState(initialValue);

  const { data: urlToPublish } = useURLToPublish({
    entityId: entityId,
    source: 'google',
    enabled: isDialogOpen,
  });

  const handleTextFieldChange: TextFieldProps['onChange'] = (event) => {
    setReviewTextToCopy(event.target.value);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const handleProceed = () => {
    if (!urlToPublish) return;
    navigator.clipboard.writeText(reviewTextToCopy);
    openInNewTab(urlToPublish);
    setIsDialogOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>
          {intl.formatMessage(submissionMessages.publishToGoogleDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {intl.formatMessage(submissionMessages.publishToGoogleDialogDescription)}
          </Typography>
          <TextField
            value={reviewTextToCopy}
            onChange={handleTextFieldChange}
            multiline
            minRows={3}
            fullWidth
            sx={{ marginBlockStart: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {intl.formatMessage(submissionMessages.publishToSourceDialogCancel)}
          </Button>
          <Button onClick={handleProceed} variant="contained" disabled={!urlToPublish}>
            {intl.formatMessage(submissionMessages.publishToSourceDialogProceed)}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
