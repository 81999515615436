import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import type { CardMediaProps } from '@mui/material';
import { useSticky } from '@trustyou/shared';
import { Box, CardMedia, Stack, Typography } from '@trustyou/ui';

import type { EntityResult } from '../../../../client';
import { messages } from '../../../../utils/messages';
import { MAX_CARD_WIDTH_PX, MAX_HEADER_IMAGE_HEIGHT_PX } from '../../constants';

type ImageType = {
  url: string;
  variant?: 'banner' | 'logo';
};

type SurveyHeaderProps = {
  image?: ImageType;
  entity: EntityResult;
  showEntityAddress?: boolean;
  pagination?: ReactNode;
};

export function SurveyHeader({ image, entity, showEntityAddress, pagination }: SurveyHeaderProps) {
  const { isSticky, headerRef } = useSticky();

  return (
    <>
      {image && <HeaderImage image={image} />}
      <Stack spacing={2} sx={{ textAlign: 'center' }}>
        <Box ref={headerRef}>
          {entity.name && (
            <Typography component="h1" variant="h5">
              {entity.name}
            </Typography>
          )}
          {showEntityAddress && (
            <Typography color="text.secondary">
              {entity.address}, {entity.city} {entity.zip}
            </Typography>
          )}
        </Box>
        {pagination && (
          <Box
            sx={{
              transition: 'all 0.3s ease',
              ...(isSticky && {
                zIndex: 1,
                position: 'fixed',
                top: 0,
                left: { xs: 0, md: 'auto' },
                right: { xs: 0, md: 'auto' },
                width: {
                  md: MAX_CARD_WIDTH_PX - 82, // 40 (padding) * 2 (left and right) + 2 (border-left and border-right) = 82
                },
                backgroundColor: 'background.paper',
                paddingBlockStart: 1,
                marginBlockStart: '0px !important',
                boxShadow: { xs: 2, md: 0 },
              }),
            }}
          >
            {pagination}
          </Box>
        )}
      </Stack>
    </>
  );
}

function HeaderImage({ image }: { image: ImageType }) {
  return (
    <Box sx={{ marginInline: -5, marginBlockStart: -5 }}>
      {image.variant === 'banner' && (
        <CustomCardMedia
          url={image.url}
          variant={image.variant}
          sx={{
            objectFit: 'contain',
          }}
        />
      )}
      {image.variant === 'logo' && (
        <Box
          sx={{
            height: MAX_HEADER_IMAGE_HEIGHT_PX,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCardMedia
            url={image.url}
            variant={image.variant}
            sx={{
              maxHeight: MAX_HEADER_IMAGE_HEIGHT_PX / 2,
              objectFit: 'contain',
            }}
          />
        </Box>
      )}
    </Box>
  );
}

function CustomCardMedia({ url, variant, sx }: CardMediaProps & ImageType) {
  const intl = useIntl();

  return (
    <CardMedia
      component="img"
      alt={intl.formatMessage(messages.surveyHeaderImageAlt)}
      image={url}
      data-image-variant={variant}
      sx={sx}
    />
  );
}
