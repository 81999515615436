import { useEffect, useRef, useState } from 'react';

export function useSticky() {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    // Create Intersection Observer
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        // Update sticky state based on intersection
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 0,
        rootMargin: '-1px 0px 0px 0px', // Trigger just as header leaves viewport
      }
    );

    // Observe header element
    if (headerRef.current) {
      observerRef.current.observe(headerRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return {
    isSticky,
    headerRef,
  };
}
