import { useIntl } from 'react-intl';

import { Button } from '@trustyou/ui';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';

type SurveySubmitButtonProps = { loading: boolean; disabled?: boolean };

export const SurveySubmitButton = ({ loading, disabled }: SurveySubmitButtonProps) => {
  const intl = useIntl();

  return (
    <Button
      type="submit"
      variant="contained"
      size="large"
      loading={loading}
      loadingPosition="end"
      disabled={disabled}
      // To avoid the warning in console from MUI: The loadingPosition="end" should be used in combination with endIcon.
      // eslint-disable-next-line react/jsx-no-useless-fragment
      endIcon={<></>}
      sx={{ width: '100%', maxWidth: 384 }}
    >
      {intl.formatMessage(loading ? submissionMessages.submitting : submissionMessages.submit)}
    </Button>
  );
};
