import { useQuery } from '@tanstack/react-query';
import { $surveySubmitApi, SURVEY_SUBMISSION_BASE_PATH } from '@trustyou/shared';

type Source = 'google' | 'tripadvisor';

export async function fetchURLToPublish({
  entityId,
  source,
}: {
  entityId: string;
  source: Source;
}) {
  const { data } = await $surveySubmitApi.get<string>(
    `${SURVEY_SUBMISSION_BASE_PATH}/url-to-publish/${entityId}/${source}`
  );
  return data;

  // Example response for hotel "ibis Belfort Danjoutin":
  // return 'https://search.google.com/local/writereview?placeid=ChIJj1bHUcc8kkcRLJyd2RoiF50';
}

export function useURLToPublish({
  entityId,
  source,
  enabled,
}: {
  source: Source;
  entityId: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ['publish-to-source', entityId, source],
    queryFn: () => fetchURLToPublish({ entityId, source }),
    enabled: enabled,
  });
}
