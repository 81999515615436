import type { FieldErrors } from 'react-hook-form';

import { z } from 'zod';

import {
  isBasicExpression,
  isCondition,
  isEmail,
  isMaxLength,
  isMaxValue,
  isMinLength,
  isMinValue,
  isMultipleSelect,
  isPhoneNumber,
  isPreEvaluatedExpression,
  isSingleSelect,
} from './type-narrowing';

import type { Condition, Question } from '../client';
import type { FormValue, FormValues } from '../survey';

export function createValidationSchema(
  questions: Question[],
  watchedValues: FormValues,
  errorMessages: Record<string, string>,
  notApplicableMap: Map<string, boolean>
) {
  const generalSchema: Record<string, z.ZodTypeAny> = {};

  questions.forEach((question) => {
    const isNotApplicable = notApplicableMap.get(question.id);
    const isApplicable = !isNotApplicable;
    const minLength = question.primitive.validations?.find(isMinLength)?.value;
    const maxLength = question.primitive.validations?.find(isMaxLength)?.value;
    const minValue = question.primitive.validations?.find(isMinValue)?.value;
    const maxValue = question.primitive.validations?.find(isMaxValue)?.value;
    const numericMinValue = minValue ? Number(minValue) : undefined;
    const numericMaxValue = maxValue ? Number(maxValue) : undefined;
    const isEmailQuestion = question.primitive.validations?.find(isEmail)?.value;
    const isPhoneNumberQuestion = question.primitive.validations?.find(isPhoneNumber)?.value;

    let schema;

    switch (question.primitive.type) {
      case 'text':
        schema = z.string();
        if (isApplicable && minLength) schema = schema.min(minLength, errorMessages.mandatory);
        if (isApplicable && maxLength) schema = schema.max(maxLength);
        if (isApplicable && isEmailQuestion) schema = schema.email();
        if (isApplicable && isPhoneNumberQuestion)
          schema = schema.regex(
            /^[+]?[0-9\s-]*$/, // Allow digits, spaces, hyphens, and optional +
            errorMessages.phoneErrorMessage
          );
        schema = schema.optional().nullable();
        break;

      case 'number':
      case 'scale':
      case 'nps':
        schema = z.number();
        if (isApplicable && numericMinValue)
          schema = schema.min(numericMinValue, errorMessages.mandatory);
        if (isApplicable && numericMaxValue) schema = schema.max(numericMaxValue);
        schema = schema.optional().nullable();
        break;

      case 'date':
        // TODO: Add some string-like validation for dates
        schema = z.string();
        schema = schema.optional().nullable();
        break;

      case 'boolean':
        schema = z.boolean();
        schema = schema.optional().nullable();
        break;

      case 'select':
        schema = z.array(z.string());
        if (isApplicable && minLength) schema = schema.min(minLength, errorMessages.mandatory);
        if (isApplicable && maxLength) schema = schema.max(maxLength);
        schema = schema.optional().nullable();
        break;

      default:
        schema = z.any().optional().nullable();
    }

    if (question.mandatory) {
      // Skip mandatory conditional questions validation when they are not displayed
      if (
        question.condition &&
        !hasActiveCondition(question.condition as Condition, watchedValues)
      ) {
        schema = schema.refine(() => true, { message: errorMessages.mandatory });
      } else {
        // Legal checkboxes must be checked (Data Privacy and Terms & Conditions)
        if (question.primitive.type === 'boolean' && question.primitive.repr === 'checkbox') {
          schema = schema.refine((value) => value === true, { message: errorMessages.mandatory });
        }
        schema = schema.refine(
          // Nullish values aren't allowed unless 'not applicable' is checked
          (value) => isNotApplicable || (value !== undefined && value !== null && value !== ''),
          {
            message: errorMessages.mandatory,
          }
        );
      }
    }

    generalSchema[question.id] = schema;
  });

  return z.object(generalSchema);
}

export function hasActiveCondition(condition: Condition, watchedValues: FormValues) {
  // TODO: Implement the logic for `or`. Right now, only `and` is exposed by backend and supported by frontend.
  const cond = condition?.cond; // 'and' | 'or'

  const expressions = condition?.expressions ?? [];
  const results = Array(expressions.length).fill(false);

  expressions.forEach((expression, index) => {
    if (isPreEvaluatedExpression(expression)) {
      results.splice(index, 1, expression.result);
    } else if (isBasicExpression(expression)) {
      const { id, operator, values } = expression;
      const watchedValue = watchedValues[id];
      if (isSingleSelect(watchedValue)) {
        if (operator === 'in') {
          results.splice(index, 1, values.includes(watchedValue));
        } else if (operator === 'not_in') {
          results.splice(index, 1, !values.includes(watchedValue));
        }
      } else if (isMultipleSelect(watchedValue as FormValue[])) {
        if (operator === 'in') {
          results.splice(
            index,
            1,
            // @ts-expect-error Argument of type 'string | number | boolean | { [key: string]: string | number | boolean | (string | number | boolean)[]; } | null' is not assignable to parameter of type 'FormValue'.
            values.some((v) => watchedValue?.includes(v))
          );
        } else if (expression.operator === 'not_in') {
          // @ts-expect-error Argument of type 'string | number | boolean | { [key: string]: string | number | boolean | (string | number | boolean)[]; } | null' is not assignable to parameter of type 'FormValue'.
          results.splice(index, 1, !values.some((v) => watchedValue?.includes(v)));
        }
      }
    }

    // Recursively process its nested conditions
    if (isCondition(expression)) {
      (expression.expressions as Condition[]).map((condition) =>
        hasActiveCondition(condition, watchedValues)
      );
    }
  });

  return results.every((result) => result);
}

export function scrollToFirstError(errors: FieldErrors<FormValues>) {
  console.warn('errors', errors);

  const firstErrorId = Object.keys(errors).at(0);
  const element = document.getElementById(firstErrorId ?? '') as HTMLElement;
  if (!element) return;

  element.scrollIntoView({ block: 'center' });
}
