import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { iconMap } from '@trustyou/shared';
import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NPSQuestion,
  NumberQuestion,
  OneToFiveOptions,
  OneToTenOptions,
  ScaleQuestion,
  SelectQuestion,
  type SelectQuestionVariant,
  SurveyMessageContent,
  SurveySectionTitle,
  TextQuestion,
  type TextQuestionVariant,
  ZeroToTenOptions,
} from '@trustyou/survey-manager';
import { Divider, Stack } from '@trustyou/ui';

import type {
  BooleanPrimitive,
  Image,
  Question,
  Questionnaire,
  ScalePrimitive,
  Section,
  SelectPrimitive,
  Text,
  WelcomeMessage,
} from '../client';
import { usePublishToSourceStore } from '../store/publish-to-source-store';
import type { FormValues } from '../survey';
import {
  SINGLE_CHECKBOXES,
  convertScaleQuestionColor,
  isMaxLength,
  isMaxValue,
  isMinLength,
  isMinValue,
  isQuestion,
  isText,
} from '../utils';

export function useSurveyContent({
  questionnaire,
  language,
}: {
  questionnaire: Questionnaire;
  language: string;
}) {
  const { control } = useFormContext<FormValues>();
  const setReviewTextQuestionId = usePublishToSourceStore((state) => state.setReviewTextQuestionId);
  const setPublishToSourceQuestionId = usePublishToSourceStore(
    (state) => state.setPublishToSourceQuestionId
  );

  const defaultLanguage = questionnaire.default_language;

  function renderWelcome(currentPage: number) {
    if (currentPage !== 1 || !questionnaire.welcome_message) return null;

    const id = questionnaire.welcome_message.id;
    const welcomeMessageContent = questionnaire.content_items[id] as WelcomeMessage;
    const title =
      welcomeMessageContent.title?.[language] ?? welcomeMessageContent.title?.[defaultLanguage];
    const description =
      welcomeMessageContent.description?.[language] ??
      welcomeMessageContent.description?.[defaultLanguage];
    return <SurveyMessageContent title={title} description={description} isWelcome />;
  }

  function renderSectionTitle(item: Section) {
    const title = item.title[language] || item.title[defaultLanguage];
    if (!title) {
      // TODO: Do we want to allow sections with empty titles? https://jira.trustyou.com/browse/SIN-982
      // return <span style={{ color: 'orange' }}>[SECTION WITHOUT TITLE]</span>;
      return null;
    }
    return <SurveySectionTitle>{title}</SurveySectionTitle>;
  }

  function renderText(item: Text) {
    return (
      <SurveyMessageContent
        title={item.title?.[language] ?? item.title?.[defaultLanguage]}
        description={item.description?.[language] ?? item.description?.[defaultLanguage]}
      />
    );
  }

  function renderImage(content: Image) {
    return <img src={content.url as string} alt={content.path} style={{ borderRadius: '4px' }} />;
  }

  function renderQuestion({ question }: { question: Question }) {
    const name = question.id ?? '';
    const isMandatory = question.mandatory;
    const title = question.title[language] || question.title[question.default_language ?? ''];
    const description =
      question.description[language] || question.description[question.default_language ?? ''];
    const notApplicableCheckboxLabel = question.use_not_applicable
      ? question.not_applicable_label?.[language]
      : undefined;
    const variant = question.primitive.repr;
    const minLength = question.primitive.validations?.find(isMinLength)?.value;
    const maxLength = question.primitive.validations?.find(isMaxLength)?.value;
    const minValue = question.primitive.validations?.find(isMinValue)?.value;
    const maxValue = question.primitive.validations?.find(isMaxValue)?.value;
    const isSingle = maxLength === 1;
    const isReadonly = question.prefilled_answer?.input_type === 'readonly';
    const isHidden = question.prefilled_answer?.input_type === 'hidden';
    const isContactInfo = question.handling_type.startsWith('contact_info');

    if (question.handling_type === 'review_text') {
      setReviewTextQuestionId(question.id);
    }

    if (
      question.handling_type === 'push_to_google' ||
      question.handling_type === 'push_to_tripadvisor'
    ) {
      setPublishToSourceQuestionId(question.id);
    }

    switch (question.primitive.type) {
      case 'text': {
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <TextQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                variant={variant as TextQuestionVariant}
                title={title}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                minCharacters={minLength}
                maxCharacters={maxLength}
                isDisabled={isReadonly}
                isHidden={isHidden}
                isMandatory={isMandatory}
                isContactInfo={isContactInfo}
              />
            )}
          />
        );
      }
      case 'select': {
        const variantMap: Record<string, SelectQuestionVariant> = {
          dropdown: isSingle ? 'dropdown_single' : 'dropdown_multiple',
        };
        const options =
          (question.primitive as SelectPrimitive).options.map((option) => ({
            value: option.value,
            label: option.label[language] ?? option.label[defaultLanguage],
            icon: iconMap[option.icon ?? ''],
            color: option.color,
          })) ?? [];
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <SelectQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                isDisabled={isReadonly}
                isHidden={isHidden}
                variant={variantMap[variant as SelectQuestionVariant] ?? variant}
                options={options}
              />
            )}
          />
        );
      }
      case 'scale': {
        const options =
          (question.primitive as ScalePrimitive).options?.map((option) => ({
            value: option.value,
            label: option.label[language] ?? option.label[defaultLanguage],
            icon: iconMap[option.icon ?? ''],
            color: option.color
              ? convertScaleQuestionColor(option.color)
              : maxValue === 5
                ? OneToFiveOptions.find((o) => o.value === option.value)?.color
                : OneToTenOptions.find((o) => o.value === option.value)?.color,
          })) ?? [];
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <ScaleQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                isDisabled={isReadonly}
                isHidden={isHidden}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                options={options}
              />
            )}
          />
        );
      }
      case 'nps': {
        const hideZero = questionnaire.ui_config?.nps_hide_zero;
        const options = ZeroToTenOptions.filter((option) => !(hideZero && option.value === 0)).map(
          (option) => {
            const npsOptions = (question.primitive as ScalePrimitive).options ?? [];
            const npsOption = npsOptions.find((o) => o.value === option.value) || null;
            const label =
              (option.value === 0
                ? (npsOptions.find((o) => o.value === 1)?.label?.[language] ??
                  npsOptions.find((o) => o.value === 1)?.label?.[defaultLanguage])
                : (npsOption?.label?.[language] ?? npsOption?.label?.[defaultLanguage])) ??
              option.label;

            return {
              value: option.value,
              label: label,
              icon: iconMap[npsOption?.icon ?? ''] || option.icon,
              color: npsOption?.color ? convertScaleQuestionColor(npsOption.color) : option.color,
            };
          }
        );
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <NPSQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                isDisabled={isReadonly}
                isHidden={isHidden}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                options={options}
              />
            )}
          />
        );
      }
      case 'number': {
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <NumberQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                isDisabled={isReadonly}
                isHidden={isHidden}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                min={minValue as number}
                max={maxValue as number}
              />
            )}
          />
        );
      }
      case 'boolean': {
        if (variant === 'checkbox') {
          const isSingleCheckbox = SINGLE_CHECKBOXES.includes(question.handling_type);
          if (isSingleCheckbox) {
            const options = [
              {
                value: 'accept',
                label: question.title[language] ?? question.title[defaultLanguage],
              },
            ];
            return (
              <Controller
                name={name}
                control={control}
                render={({ field, fieldState }) => (
                  <BooleanQuestion
                    id={question.id}
                    field={field}
                    errorMessage={fieldState.error?.message}
                    isDisabled={isReadonly}
                    isHidden={isHidden}
                    isMandatory={isMandatory}
                    variant={variant}
                    isSingleCheckbox={isSingleCheckbox}
                    options={options}
                  />
                )}
              />
            );
          }
          const options = [
            {
              label: question.title[language] ?? question.title[defaultLanguage],
              value: question.title[language] ?? question.title[defaultLanguage],
            },
          ];
          return (
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState }) => (
                <BooleanQuestion
                  id={question.id}
                  field={field}
                  errorMessage={fieldState.error?.message}
                  isDisabled={isReadonly}
                  isHidden={isHidden}
                  title={title}
                  isMandatory={isMandatory}
                  description={description}
                  notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                  variant={variant}
                  options={options}
                />
              )}
            />
          );
        }
        // Default BooleanQuestion that renders Yes/No options with some colored icons.
        const options =
          (question.primitive as BooleanPrimitive).options?.map((option) => ({
            value: option.value,
            label: option.label[language] ?? option.label[defaultLanguage],
            icon: iconMap[option.icon ?? ''],
            color: option.color,
          })) ?? [];
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <BooleanQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                isDisabled={isReadonly}
                isHidden={isHidden}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                variant={variant as BooleanQuestionVariant}
                options={options}
              />
            )}
          />
        );
      }
      case 'date': {
        const variantMap: Record<string, DateQuestionVariant> = {
          datepicker: 'day-month-year',
          year_month_picker: 'month-year',
        };
        return (
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <DateQuestion
                id={question.id}
                field={field}
                errorMessage={fieldState.error?.message}
                isDisabled={isReadonly}
                isHidden={isHidden}
                title={title}
                isMandatory={isMandatory}
                description={description}
                notApplicableCheckboxLabel={notApplicableCheckboxLabel}
                variant={variantMap[variant as DateQuestionVariant] ?? variant}
              />
            )}
          />
        );
      }
      default:
        // return (
        //   <span style={{ color: 'orange' }}>
        //     Unhandled primitive type <b>{question.primitive.type}</b> with id <b>{question.id}</b>
        //   </span>
        // );
        return null;
    }
  }

  function renderSubmitSection(children?: ReactNode) {
    if (!questionnaire.submit_section) return null;
    const submitSectionItems = questionnaire.submit_section.content.map(
      (item) => questionnaire.content_items[item.id]
    );
    const submitSectionQuestions = submitSectionItems.filter(isQuestion);
    const submitSectionTexts = submitSectionItems.filter(isText);
    const nameQuestion = submitSectionQuestions.find(
      (item) => item.handling_type === 'contact_info_name'
    );
    const roomQuestion = submitSectionQuestions.find(
      (item) => item.handling_type === 'contact_info_room_number'
    );
    const emailQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'contact_info_email'
    );
    const phoneQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'contact_info_phone'
    );
    const privacyQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'data_privacy_information'
    );
    const termsQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'terms_and_conditions'
    );
    const pushToSourceQuestion = submitSectionQuestions.find(
      (question) =>
        question.handling_type === 'push_to_google' ||
        question.handling_type === 'push_to_tripadvisor'
    );
    const termsAndPrivacyText = submitSectionTexts.at(0) as Text;
    return (
      <>
        {renderSectionTitle(
          questionnaire.content_items[questionnaire.submit_section.id] as Section
        )}
        <Stack spacing={3}>
          {nameQuestion && renderQuestion({ question: nameQuestion })}
          {roomQuestion && renderQuestion({ question: roomQuestion })}
          {emailQuestion && renderQuestion({ question: emailQuestion })}
          {phoneQuestion && renderQuestion({ question: phoneQuestion })}
          <Stack>
            {privacyQuestion && renderQuestion({ question: privacyQuestion })}
            {termsQuestion && renderQuestion({ question: termsQuestion })}
            {/* {pushToProviderQuestion && renderQuestion(pushToProviderQuestion)} */}
            {termsAndPrivacyText && (
              <SurveyMessageContent
                title={
                  termsAndPrivacyText.title?.[language] ??
                  termsAndPrivacyText.title?.[defaultLanguage]
                }
                description={
                  termsAndPrivacyText.description?.[language] ??
                  termsAndPrivacyText.description?.[defaultLanguage]
                }
              />
            )}
          </Stack>
          {pushToSourceQuestion && (
            <>
              <Stack sx={{ alignItems: 'center' }}>
                <Divider sx={{ width: 220 }} />
              </Stack>
              {renderQuestion({ question: pushToSourceQuestion })}
            </>
          )}
        </Stack>
        {children}
      </>
    );
  }

  return {
    renderWelcome,
    renderSectionTitle,
    renderText,
    renderImage,
    renderQuestion,
    renderSubmitSection,
  };
}
