import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CustomCard,
  SurveyHeader,
  SurveyPagination,
  SurveySubmitButton,
  useFeedbackPaginationStore,
} from '@trustyou/survey-manager';
import { Box, Stack } from '@trustyou/ui';

import { AutoReloadCountdown } from './auto-reload-countdown';
import type { EntityResult, Image, Questionnaire, ThankyouMessage } from './client';
import { useSurveyContent } from './hooks/use-survey-content';
import { useVisibleItems } from './hooks/use-visible-items';
import { ThankYou } from './thank-you';
import type { Content } from './types';
import { isImage, isQuestion, isSection, isText } from './utils';
import { scrollToFirstError } from './utils/validation';

type PageContentProps = {
  questionnaire: Questionnaire;
  entity: EntityResult;
  language: string;
  isPreview: boolean;
  shouldShowThankYouPage: boolean;
  shouldAutoReload: boolean;
  outsideTextColor: string;
};

export function PageContent({
  questionnaire,
  entity,
  language,
  isPreview,
  shouldShowThankYouPage,
  shouldAutoReload,
  outsideTextColor,
}: PageContentProps) {
  const { content, content_items, theme } = questionnaire;
  const {
    formState: { isSubmitting, errors },
    trigger,
    watch,
  } = useFormContext();
  const { currentPage } = useFeedbackPaginationStore();

  const pageCount = content?.length ?? 1;
  const hasPages = pageCount > 1;
  const isLastPage = currentPage === pageCount;
  const pageItems = content.find((item) => item.page === currentPage - 1);
  const contentIds =
    pageItems?.content?.flatMap((item) => {
      if (isSection(item)) {
        const section = item;
        const sectionTitleId = section.id;
        const sectionItemIds = section.content?.map((sectionItem) => sectionItem.id) ?? [];
        return [sectionTitleId, ...sectionItemIds];
      }
      return item.id;
    }) ?? [];

  const headerImage = content_items[theme?.header_image?.id ?? ''] as Image;
  const shouldRenderHeader = headerImage || entity || pageCount > 1;

  const visibleItems = useVisibleItems(
    contentIds.map((id) => content_items[id]),
    watch()
  );
  const visibleQuestions = visibleItems.filter(isQuestion);

  const thankYouMessage = content_items[questionnaire.thankyou_message?.id ?? ''] as
    | ThankyouMessage
    | undefined;

  const handlePageValidation = async () => {
    const questionIds = visibleQuestions.map((question) => question.id);
    const isValid = await trigger(questionIds, { shouldFocus: true });
    if (isValid) {
      window.scroll({ top: 0 });
    } else {
      scrollToFirstError(errors);
    }
    return isValid;
  };

  const {
    renderWelcome,
    renderSectionTitle,
    renderText,
    renderImage,
    renderQuestion,
    renderSubmitSection,
  } = useSurveyContent({ questionnaire, language });

  const renderContentItem = (item: Content) => {
    if (isSection(item)) return renderSectionTitle(item);
    if (isQuestion(item)) return renderQuestion({ question: item });
    if (isText(item)) return renderText(item);
    if (isImage(item)) return renderImage(item);
    console.warn(
      `Unhandled content type ${(item as Content).type} with id ${(item as Content).id}`
    );
    return null;
  };

  return (
    <>
      <CustomCard>
        {shouldRenderHeader && (
          <SurveyHeader
            image={
              headerImage
                ? {
                    url: headerImage.url ?? '',
                    variant:
                      // FIXME: Using `banner` as fallback. `metadata.classic_info?.type` is not defined in some migrated surveys
                      (headerImage.metadata.classic_info?.type as 'banner' | 'logo') ?? 'banner',
                  }
                : undefined
            }
            entity={entity}
            showEntityAddress={questionnaire.ui_config?.show_entity_address}
            pagination={
              !shouldShowThankYouPage &&
              pageCount > 1 && (
                <SurveyPagination
                  pageCount={pageCount}
                  showProgressBar
                  onValid={handlePageValidation}
                />
              )
            }
          />
        )}
        <Stack sx={{ paddingBlockStart: shouldRenderHeader ? 3 : 5 }}>
          {shouldShowThankYouPage ? (
            <ThankYou message={thankYouMessage} language={language} />
          ) : (
            <Stack sx={{ gap: { xs: 3, sm: 5 }, paddingBlockStart: shouldRenderHeader ? 3 : 0 }}>
              {renderWelcome(currentPage)}
              {visibleItems.map((item) => (
                <Fragment key={item.id}>{renderContentItem(item)}</Fragment>
              ))}
              {isLastPage &&
                questionnaire.submit_section &&
                renderSubmitSection(
                  <Stack sx={{ alignItems: 'center' }}>
                    <SurveySubmitButton loading={isSubmitting} disabled={isPreview} />
                  </Stack>
                )}
              {hasPages && !isLastPage && (
                <Stack sx={{ gap: { xs: 2, sm: 3 } }}>
                  <SurveyPagination pageCount={pageCount} onValid={handlePageValidation} />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </CustomCard>
      {shouldShowThankYouPage && shouldAutoReload && (
        <Box sx={{ paddingBlock: 2, textAlign: 'center' }}>
          <AutoReloadCountdown textColor={outsideTextColor} />
        </Box>
      )}
    </>
  );
}
